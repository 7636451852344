<template>
  <div class="layout">

    <div class="layout_content">
      <main class="page">
        <div class="container-fluid">
          <div class="row align-items-center justify-content-center justify-content-lg-start">
            <div class="col-auto offset-xl-1">
              <div class="auth-form">
                <nuxt-link to="/"><img class="auth-form__logo" alt="ExpoMobility" :src="logo"/></nuxt-link>
                <NuxtPage />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>

  </div>

</template>

<script setup lang="ts">
  import logo from "~/components/layout/img/logo.svg";
  import {onMounted, ref} from "vue";
  import auth from "~/utils/auth";

  const router = useRouter();

  let errors = ref({
    message: null!,
    errors: {

    }
  });

  onMounted(() => {
    auth.isAuth(() => {
      router.push({ path: "/" });
    });
  })
</script>

<style>
  .auth-form p{
    font-size: 14px;
  }
  .preloader.for-button{
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    height: 50%;
    width: 50%;
    z-index: 1;
  }
</style>
<style scoped>
  .page > .container-fluid > .row{
    min-height: 100vh;
  }
  .page:before{
    content: '';
    width: 52.03125%;
    height: 74.074%;
    position: fixed;
    right: 0;
    bottom: 0;
    background: url('../components/layout/img/decor-orange.svg') right bottom / contain no-repeat;
    z-index: -1;
  }
  .page:after{
    content: '';
    z-index: -1;
    position: fixed;
    right: 0;
    bottom: 0;
    width: 67.24%;
    height: 51.111%;
    background: url('../components/layout/img/decor-car.png') right bottom / contain no-repeat;
  }

  .auth-form{
    border-radius: 15px;
    background: #fff;
    padding: 35px 1.5rem;
    width: 444px;
    max-width: 100%;
    margin: 1.5rem 0;
  }
  .auth-form__logo{
    width: 244px;
    max-width: 80%;
    margin: 0 auto 2.7rem;
    display: block;
  }

  @media(max-width: 991px){
    .page:before{
      width: 68%;
      height: 100%;
      background-image: url('../components/layout/img/decor-orange-mobile.svg');
      background-size: 100% 100%;
    }
    .page:after{
      width: 100%;
    }
  }
</style>
